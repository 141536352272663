/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Gmb from "../images/gmb.svg"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          sub_title
        }
      }
    }
  `)

  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        siteSubTitle={data.site.siteMetadata.sub_title}
      />
      <main>{children}</main>
      <footer
        style={{
          display: `flex`,
          justifyContent: `center`,
          padding: `.5rem 0`,
          backgroundColor: `#293039`,
          borderTop: `1px solid #add137`,
        }}
      >
        <a
          style={{ width: 48, height: 37 }}
          href="https://www.odiarioonline.com.br"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Gmb />
        </a>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
