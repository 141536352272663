import React, { useState } from "react"
import Logo from "../images/logo.svg"
import { Link } from "gatsby"
import { slide as Menu } from "react-burger-menu"

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  const handleStateChange = state => {
    setMenuOpen(state.isOpen)
  }

  const handleCloseMenu = () => {
    setMenuOpen(false)
  }

  return (
    <header
      style={{
        background: `#293039`,
        height: `100vh`,
      }}
    >
      <div id="menu-responsive">
        <Menu
          disableAutoFocus
          onStateChange={handleStateChange}
          isOpen={menuOpen}
        >
          <Link className="bm-item-list" to="/#about" onClick={handleCloseMenu}>
            SOBRE NÓS
          </Link>
          <Link
            className="bm-item-list"
            to="/#products"
            onClick={handleCloseMenu}
          >
            PRODUTOS
          </Link>
          <a
            className="bm-item-list"
            href="http://esouth.logycware.com/EmVista/disponibilidade/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleCloseMenu}
          >
            PAINÉIS DISPONÍVEIS
          </a>
          <a
            className="bm-item-list"
            href="http://esouth.logycware.com/EmVista/locais/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleCloseMenu}
          >
            TODOS OS PAINÉIS
          </a>
          <Link
            className="bm-item-list"
            to="/#contact"
            onClick={handleCloseMenu}
          >
            CONTATO
          </Link>
        </Menu>
      </div>
      <div
        style={{
          display: `flex`,
          flexDirection: `column`,
          alignItems: `center`,
          height: `100vh`,
        }}
      >
        <nav id="menu" style={{ width: 800, height: 30, marginTop: `1.45rem` }}>
          <ul style={{ display: `flex`, justifyContent: `space-between` }}>
            <li>
              <Link to="/#about">SOBRE NÓS</Link>
            </li>
            <li>
              <Link to="/#products">PRODUTOS</Link>
            </li>
            <li>
              <a
                href="http://esouth.logycware.com/EmVista/disponibilidade/"
                target="_blank"
                rel="noopener noreferrer"
              >
                PAINÉIS DISPONÍVEIS
              </a>
            </li>
            <li>
              <a
                href="http://esouth.logycware.com/EmVista/locais/"
                target="_blank"
                rel="noopener noreferrer"
              >
                TODOS OS PAINÉIS
              </a>
            </li>
            <li>
              <Link to="/#contact">CONTATO</Link>
            </li>
          </ul>
        </nav>
        <div
          style={{
            minWidth: 350,
            maxWidth: 600,
            width: `100%`,
            margin: `auto 0`,
          }}
        >
          <Logo />
        </div>
      </div>
    </header>
  )
}

export default Header
